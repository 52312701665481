import { AllowedVerb, OptionalData, ResponseData } from '../types'
const DEFAULT_METHOD = 'get'

// TODO: look if there is possible to replace with
// iframe for higher page load speeds thanks to
// https://jakearchibald.com/2016/fun-hacks-faster-content/
const fetchHTMLDocument = async (
  href: URL,
  verb?: AllowedVerb,
  data?: OptionalData
) => {
  verb = verb || DEFAULT_METHOD
  const url = new URL(href)
  url.searchParams.append('ref', document.referrer)
  const response = await fetch(url.toString())
  const responseText = await response.text()
  return {
    document: new DOMParser().parseFromString(responseText, 'text/html'),
    status: response.status,
  } as ResponseData
}

export default fetchHTMLDocument
