import { patchLinks, validLinkHref, serializeFormData } from './dom'
import { Config, HistoryState } from '../types'
import { pushState } from './navigation'
import isEnabled, { FLAG_KEEP_SCROLL_POSITION } from './flags'

export const EVT_PAGE_NAVIGATED = 'kundo-knowledge:page-navigated'
export const EVT_SCRIPT_LOADED = 'kundo-knowledge:script-loaded'
export const EVT_SEARCH_RESULTS_UPDATED =
  'kundo-knowledge:search-results-updated'
export const EVT_GUIDE_VOTED = 'kundo-knowledge:guide-voted'
export const EVT_CONTACT_CHANNEL_USED = 'kundo-knowledge:contact-channel-used'
export const EVT_CONTACT_FORM_SUBMITTED =
  'kundo-knowledge:contact-form-submitted'

/**
 * Event listener to update search results links.
 * The event should be dispatched from Knowledge frontend javascript.
 */
export const patchAttachedSearchResultsOnUpdate = (config: Config) => {
  document.addEventListener(EVT_SEARCH_RESULTS_UPDATED, (e: CustomEvent) => {
    const results = e.detail
    patchLinks(results, config.baseUrl)
  })
}

/**
 * Event listener to initiate Knowledge Frontend JavaScript.
 * Dispatch DOMContentLoaded when scripts has been loaded: this event
 * should be dispatched from Knowledge frontend JavaScript.
 */
export const initScriptsWhenLoaded = (container: ShadowRoot) => {
  document.addEventListener(EVT_SCRIPT_LOADED, () => {
    const body = container.querySelector('body')
    if (body) {
      dispatchEvent(body, 'DOMContentLoaded')
    }
  })
}

/**
 * Captures clicks, prevent default action and instead perform other actions.
 * For now, only deals with link navigation.
 */
export const captureClicks = (
  config: Config,
  element: HTMLBodyElement | HTMLDivElement,
  callback: Function
) => {
  // TODO: write tests
  element.addEventListener('click', (event: MouseEvent) => {
    let target = <HTMLElement>event.target
    let linkElement: HTMLAnchorElement
    if (target.nodeName === 'A') {
      linkElement = <HTMLAnchorElement>target
    } else if (target.parentElement && target.parentElement.nodeName === 'A') {
      linkElement = <HTMLAnchorElement>target.parentElement
    } else {
      let element = target
      while (element) {
        if (element.nodeName === 'A') {
          linkElement = <HTMLAnchorElement>element
          break
        }
        element = element.parentElement
      }
    }
    if (linkElement) {
      const href = new URL(linkElement.href)
      if (validLinkHref(linkElement.getAttribute('href'))) {
        event.preventDefault()
        callback(href)
        pushState(href)
        if (!isEnabled(config, FLAG_KEEP_SCROLL_POSITION)) {
          window.scrollTo(0, 0)
        }
      }
    }
  })
}

export const captureFormSubmits = (
  body: HTMLBodyElement,
  callback: Function
) => {
  const form = <HTMLFormElement>body.querySelector('.fn-search-form')
  if (form) {
    form.addEventListener('submit', (event: Event) => {
      event.preventDefault()
      const data = serializeFormData(form)
      const action = encodeURIComponent(form.getAttribute('action'))
      const href = new URL(
        `${location.href.split('?')[0]}?path=${action}${encodeURI('?' + data)}`
      )
      callback(href)
      pushState(href)
    })
  }
}

export const capturePopState = (callback: Function) => {
  window.addEventListener('popstate', (event?: PopStateEvent) => {
    if (event.state) {
      const { href } = <HistoryState>event.state
      callback(new URL(href))
    } else {
      callback(new URL(location.toString()))
    }
  })
}

export const dispatchEvent = (element: HTMLElement, eventName: string) => {
  element.dispatchEvent(
    new Event(eventName, {
      bubbles: true,
      cancelable: true,
      composed: true,
    })
  )
}

export const setupEventListeners = (container: ShadowRoot, config: Config) => {
  patchAttachedSearchResultsOnUpdate(config)
  initScriptsWhenLoaded(container)
}
