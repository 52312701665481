import EmbedApp from './app'
import { Config } from './types'

declare global {
  interface Window {
    KUNDO?: Config
  }
}

const config = window.KUNDO || {}

EmbedApp(config)
