import { Config, HistoryState, ResponseData } from '../types'

const KB_CSS_FILE = '/css/knowledge.css'
const KB_JS_FILE = '/js/knowledge.js'

const KB_CUSTOM_FONT_NAMES = {
  bodyFont: 'kundoKbCustomBodyFont',
  bodyBoldFont: 'kundoKbCustomBodyBoldFont',
  headingFont: 'kundoKbCustomHeadingFont',
}

export const UNWANTED_CONTENT = [
  '.top-area__logotype',
  '.knowledge-name',
  '.toplist',
  '.footer',
]

/**
 * Make link navigation work by setting the link href to an encoded path param.
 * Skip anchors, placeholders and external links!
 * Example: '/abc/123' => '?path=%2Fabc%2F123%2F'
 */
export const patchLinks = (
  element: HTMLBodyElement | HTMLDivElement,
  baseUrl: string
) => {
  const links = element.getElementsByTagName('a')
  for (var i = 0; i < links.length; i++) {
    let href = links[i].getAttribute('href') || ''
    href = href.replace(baseUrl, '')
    if (href) {
      if (validLinkHref(href)) {
        links[i].href = `?path=${encodeURIComponent(href)}`
      } else if (href.match(/^\/uploaded_files\//)) {
        links[i].href = `${baseUrl}${href}`
      }
      if (links[i].getAttribute('href') === '?path=%2F') {
        links[i].setAttribute('href', '?')
      }
    }
  }
}

export const validLinkHref = (href: string) => {
  if (href === null) {
    return false
  }
  const skipPrefixes = [
    '#',
    '//',
    'http:',
    'https:',
    'javascript:',
    'mailto:',
    'tel:',
    '/uploaded_files/',
  ]
  return !skipPrefixes.some((prefix) => href.startsWith(prefix))
}

export const serializeFormData = (form: HTMLFormElement) => {
  const formData = new FormData(form)
  const data = new URLSearchParams()
  formData.forEach((value: string, key: string) => {
    data.append(key, value)
  })
  return data.toString()
}

/**
 * Remove unwanted DOM nodes in the embedded version of the page.
 * For now: Logo and Name of KB.
 */
export const removeContent = (element: HTMLBodyElement | HTMLDivElement) => {
  UNWANTED_CONTENT.forEach((selector) => {
    const child = element.querySelector(selector)
    if (child) {
      child.parentNode.removeChild(child)
    }
  })
}

/**
 * Add and remove style modifiers in the embedded version of the page.
 */
export const patchContent = (element: HTMLBodyElement) => {
  const topArea = element.querySelector('.top-area')

  topArea.classList.remove('top-area--compact')
  topArea.classList.add('top-area--embed')
}

/**
 * Prepare the fetched DOM document before attaching it as Shadow.
 */
export const prepareBody = (
  config: Config,
  body: HTMLBodyElement
): HTMLBodyElement => {
  patchLinks(body, config.baseUrl)
  removeContent(body)
  patchContent(body)
  return body
}

/**
 * Extract all HTMLLinkElements from a DOM document head
 */
const getStylesheets = (doc: HTMLDocument): Array<HTMLLinkElement> => {
  const headerLinks = Array.prototype.slice.call(
    doc.head.getElementsByTagName('link')
  )
  return headerLinks.filter(
    (link: HTMLLinkElement) => link.rel === 'stylesheet'
  )
}

/**
 * Attach main css file (and custom styles if present) to ShadowRoot container
 */
export const attachStyles = (
  baseUrl: string,
  sourceDoc: HTMLDocument,
  container: ShadowRoot
) => {
  const styleSheets = getStylesheets(sourceDoc)
  if (styleSheets.length < 2) {
    // local development only: add main css file manually, since it is
    // not attached using <link> in development mode (webpack hot reload
    // use websockets).
    const mainStyles = document.createElement('link')
    mainStyles.rel = 'stylesheet'
    mainStyles.href = `${baseUrl}${KB_CSS_FILE}`
    container.appendChild(mainStyles)
  }
  styleSheets.forEach((stylesheet: HTMLLinkElement) => {
    const href = stylesheet.getAttribute('href')
    stylesheet.href = `${baseUrl}${href}`
    stylesheet.onload = () => {
      setTimeout(() => {
        ;(<HTMLBodyElement>container.querySelector('body')).style.display = ''
      }, 11)
    }
    container.appendChild(stylesheet)
  })
}

const getFontFaceRule = (
  sourceDoc: HTMLDocument,
  fontKey: string,
  config: Config
): string => {
  const fontUrl = sourceDoc.body.dataset[fontKey]
  const name = KB_CUSTOM_FONT_NAMES[fontKey] || null
  if (fontUrl && name) {
    return `
      @font-face {
        font-family: ${name};
        src: url(${config.baseUrl}${fontUrl});
        font-style: normal;
        font-display: swap;
      }
    `
  }
  return ''
}

/**
 * Attach @font-face declarations to ownerDocument head, to make
 * shadow dom acknowledge the custom font-face of the KB.
 */
export const attachFontFaces = (config: Config, sourceDoc: HTMLDocument) => {
  let fontFaceStyles = ''
  for (let fontKey of Object.keys(KB_CUSTOM_FONT_NAMES)) {
    fontFaceStyles += getFontFaceRule(sourceDoc, fontKey, config)
  }
  if (fontFaceStyles.length) {
    const styleElement = document.createElement('style')
    styleElement.textContent = fontFaceStyles
    document.head.appendChild(styleElement)
  }
}

/**
 * Attach main js file to ShadowRoot container
 */
export const attachScripts = (baseUrl: string, container: ShadowRoot) => {
  const script = document.createElement('script')
  script.src = `${baseUrl}${KB_JS_FILE}`
  container.appendChild(script)
}

/**
Overwrite Host documents's title, description, and Open Graph data with Shadow
DOM data
*/
export const updateMetaData = (response: ResponseData) => {
  const { head: parentHead } = document
  const { head: embedHead } = response.document

  const selectors = [
    'title',
    'meta[property="og:description"]',
    'meta[property="og:type"]',
    'meta[name="description"]',
    'meta[name="robots"]',
    'meta[name="googlebot"]',
    'meta[name="bingbot"]',
  ]
  for (const selector of selectors) {
    const original = parentHead.querySelector(selector)
    const embed = embedHead.querySelector(selector)
    if (!embed) {
      if (original && selector.match(/bot/)) {
        // remove meta robots to avoid accidently
        // have lingering "noindex,nofollow" robots
        original.remove()
      }
      continue
    }
    if (original) {
      original.replaceWith(embed)
    } else {
      parentHead.appendChild(embed)
    }
  }
}

/**
 * Overwrite Host documents's meta robots if status code is 400 or above
 * Don't index the parent page if the response contains an error.
 * DOM data
 */
export const updateMetaRobots = (response: ResponseData) => {
  const { head: parentHead } = document
  if (response.status >= 400) {
    const selectors = ['robots', 'googlebot', 'bingbot']
    for (const selector of selectors) {
      const original = parentHead.querySelector(`meta[name="${selector}"]`)
      const meta = document.createElement('meta')
      meta.name = selector
      meta.content = 'noindex,nofollow'
      if (original) {
        original.replaceWith(meta)
      } else {
        parentHead.appendChild(meta)
      }
    }
  }
}

/**
 * Reset initial loading styles for placeholder
 */
export const resetPlaceholderStyles = (placeholder: HTMLElement): void => {
  placeholder.style.minHeight = ''
}

export const attachGoogleAnatytics = (
  domdoc: HTMLDocument,
  shadow: ShadowRoot
) => {
  const originalLoadScript = <HTMLScriptElement>(
    domdoc.getElementById('kundo-gtag-load')
  )

  if (originalLoadScript == null) {
    return
  }
  const configScriptElement = domdoc.getElementById('kundo-gtag-config')

  const loadScriptElement = document.createElement('script')
  loadScriptElement.src = originalLoadScript.src
  loadScriptElement.async = true

  shadow.appendChild(loadScriptElement)
  new Function(configScriptElement.textContent)()
}

export const getCanonicalLink = (doc: Document): URL => {
  let canonical: HTMLLinkElement | null =
    doc.head.querySelector('[rel=canonical]')
  if (canonical) {
    return new URL(canonical.href)
  } else {
    return new URL(location.href)
  }
}

export const setCanonicalLink = (url: URL): void => {
  let canonical: HTMLLinkElement | null =
    document.head.querySelector('[rel=canonical]')
  if (!canonical) {
    canonical = document.createElement('link')
    canonical.rel = 'canonical'
    document.head.appendChild(canonical)
  }
  canonical.href = url.toString()
}
