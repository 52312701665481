import { Config } from './types'
import navigate, { getFetchUrl } from './lib/navigation'
import { setupEventListeners } from './lib/events'

const DEFAULT_PLACEHOLDER = '#kundo-knowledge-embed'
export const DEFAULT_LANGUAGE = 'sv'
export const DEFAULT_FLAGS = []

let container: ShadowRoot
let config: Config

export const degradeGracefully = (
  config: Config,
  placeholder: HTMLElement,
  goto: string
) => {
  const translations = {
    sv: {
      title: 'Innehållet kan inte visas',
      text: `Din webbläsare är för gammal och du behöver därför <a href="${goto}" rel="noopener noreferrer" target="_blank">öppna innehållet i en ny flik</a> för att kunna läsa.`,
    },
    da: {
      title: 'Indholdet kan ikke vises',
      text: `Din webbrowser er for gammel og du skal derfor <a href="${goto}" rel="noopener noreferrer" target="_blank">åbne indholdet i en ny tab</a> for at kunne læse.`,
    },
    de: {
      title: 'Der Inhalt kann nicht angezeigt werden',
      text: `Ihr Browser ist zu alt, weshalb Sie <a href="${goto}" rel="noopener noreferrer" target="_blank"> den Inhalt in einem neuen Tab öffnen müssen,</a> um lesen zu können.`,
    },
    en: {
      title: 'Content cannot be displayed',
      text: `Your web browser is too old, so you need to <a href="${goto}" rel="noopener noreferrer" target="_blank">open the content in a new window </a> to be able to read it.`,
    },
    es: {
      title: 'El contenido no se puede mostrar',
      text: `Su navegador es demasiado antiguo y, por lo tanto, debe <a href="${goto}" rel="noopener noreferrer" target="_blank">abrir el contenido en una nueva pestaña</a> para poder leer.`,
    },
    fi: {
      title: 'Sisältöä ei voida näyttää',
      text: `Selaimesi on liian vanha ja sen takia sinun täytyy <a href="${goto}" rel="noopener noreferrer" target="_blank">avata sisältö uudessa välilehdessä</a> voidaksesi lukeasen.`,
    },
    nb: {
      title: 'De inhoud kan niet worden getoond',
      text: `Uw browser is te oud en u moet daarom <a href="${goto}" rel="noopener noreferrer" target="_blank">de inhoud in een nieuw tabblad openen</a> om deze te kunnen bekijken.`,
    },
    pl: {
      title: 'Brak możliwości wyświetlenia treści',
      text: `Ponieważ posiadasz nieaktualną wersję przeglądarki, musisz <a href="${goto}" rel="noopener noreferrer" target="_blank">otworzyć treść w nowej zakładce</a>, aby móc ją odczytać.`,
    },
  }
  const languageKeys = Object.keys(translations)
  const language =
    languageKeys.indexOf(config.language) === -1
      ? DEFAULT_LANGUAGE
      : config.language
  const textContent = translations[language]
  const content = `
  <div data-language="${language}" style="padding: 5em 0; display: flex; align-items: center; justify-content: center;">
    <div>
      <h1>${textContent.title}</h1>
      <p>${textContent.text}</p>
    </div>
  </div>
  `
  placeholder.insertAdjacentHTML('beforeend', content)
  placeholder.style.minHeight = ''
}

export const install = async () => {
  setupEventListeners(container, config)
  const href = getFetchUrl(config)
  await navigate(config, container, href)
}

export const createContainer = (placeholder: HTMLElement): void => {
  placeholder.attachShadow({ mode: 'open' })
  container = placeholder.shadowRoot
}

export const configure = (config: Config, placeholder: HTMLElement): Config => {
  const configKeys = ['baseUrl', 'language']
  config.baseUrl = config.baseUrl || location.origin
  config.language = config.language || DEFAULT_LANGUAGE
  config.flags = config.flags || DEFAULT_FLAGS
  for (let key of configKeys) {
    if (placeholder.dataset[key]) {
      config[key] = placeholder.dataset[key]
    }
  }
  return config
}

const initApp = (loadedConfig: Config): void => {
  config = loadedConfig
  const selector = config.placeholder || DEFAULT_PLACEHOLDER
  const placeholder = <HTMLElement | undefined>document.querySelector(selector)

  if (placeholder === undefined) {
    console.info(
      'No Kundo Knowledge placeholder found, searched for:',
      selector
    )
    return
  }

  config = configure(config, placeholder)
  if (placeholder.attachShadow && URL) {
    createContainer(placeholder)
    install()
  } else {
    let path = ''
    const provided = location.search.match(/\?path=([^&]+)/)
    if (provided) {
      path = decodeURIComponent(provided[1])
    }
    const goto = `${config.baseUrl}${path}`
    degradeGracefully(config, placeholder, goto)
  }
}

export default initApp
