import { Config } from '../types'

// when used, do not go to top on page when navigation occours.
export const FLAG_KEEP_SCROLL_POSITION = 'keepScrollPosition'

export const ALLOWED_FLAGS = [FLAG_KEEP_SCROLL_POSITION]

export default (config: Config, flag: string) => {
  return ALLOWED_FLAGS.includes(flag) && config.flags.includes(flag)
}
