import { AllowedVerb, OptionalData, Config, HistoryState } from '../types'
import fetchHTMLDocument from './http'
import {
  prepareBody,
  attachScripts,
  attachStyles,
  updateMetaData,
  attachFontFaces,
  resetPlaceholderStyles,
  attachGoogleAnatytics,
  setCanonicalLink,
  updateMetaRobots,
  getCanonicalLink,
} from './dom'
import {
  captureClicks,
  captureFormSubmits,
  capturePopState,
  dispatchEvent,
  EVT_PAGE_NAVIGATED,
} from './events'

export const getFetchUrl = (
  config: Config,
  href: URL = new URL(window.location.href)
): URL => {
  const path = href.searchParams.get('path') || ''
  return new URL(`${config.baseUrl}${path}`)
}

const navigate = async (
  config: Config,
  container: ShadowRoot,
  href: URL,
  verb?: AllowedVerb,
  data?: OptionalData
) => {
  const response = await fetchHTMLDocument(href)
  const doc = response.document
  const body = prepareBody(config, <HTMLBodyElement>doc.body)
  captureClicks(config, body, (href: URL) => {
    navigate(config, container, getFetchUrl(config, href))
  })
  captureFormSubmits(body, (href: URL) => {
    navigate(config, container, getFetchUrl(config, href))
  })

  updateMetaData(response)
  updateMetaRobots(response)
  const canonicalLinkURL = getCanonicalLink(doc)
  setCanonicalLink(canonicalLinkURL)

  if (container.childNodes.length === 0) {
    body.style.display = 'none'
    // Initial page load: attach DOM, styles and scripts
    capturePopState((href: URL) => {
      navigate(config, container, getFetchUrl(config, href))
    })
    attachFontFaces(config, doc)
    attachStyles(config.baseUrl, doc, container)
    container.appendChild(body)
    resetPlaceholderStyles(<HTMLElement>container.host)
    attachScripts(config.baseUrl, container)
  } else {
    // navigation: replace DOM, keep styles, reinitiate scripts
    const ref = container.querySelector('body')
    container.replaceChild(body, ref)
    dispatchEvent(body, 'DOMContentLoaded')
    dispatchEvent(<HTMLElement>container.host, EVT_PAGE_NAVIGATED)
  }

  attachGoogleAnatytics(doc, container)
}

export const pushState = (href: URL) => {
  history.pushState(
    <HistoryState>{ href: href.toString() },
    document.title || '',
    href.toString()
  )
}

export default navigate
